// src/components/SnowEffect/SnowEffect.js
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './SnowEffect.css';

const SnowEffect = () => {
    const [snowflakes, setSnowflakes] = useState([]);

    useEffect(() => {
        // Generate snowflakes at random positions
        const interval = setInterval(() => {
            const newSnowflake = {
                left: Math.random() * 100 + 'vw',
                size: Math.random() * 5 + 5, // random size between 5 and 10 pixels
                id: Math.random(),
            };
            setSnowflakes((prev) => [...prev, newSnowflake]);

            // Remove snowflake after it falls out of view
            setTimeout(() => {
                setSnowflakes((prev) => prev.filter((flake) => flake.id !== newSnowflake.id));
            }, 4000); // snowflake falls for 4 seconds
        }, 200); // create a new snowflake every 200ms

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="snow-effect">
            {snowflakes.map((flake) => (
                <motion.div
                    key={flake.id}
                    className="snowflake"
                    initial={{ y: -10, opacity: 0 }}
                    animate={{ y: "100vh", opacity: 1 }}
                    transition={{ duration: 4, ease: "linear" }}
                    style={{
                        left: flake.left,
                        width: flake.size + 'px',
                        height: flake.size + 'px',
                    }}
                />
            ))}
        </div>
    );
};

export default SnowEffect;