// src/components/BackgroundAnimation/BackgroundAnimation.js
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './BackgroundAnimation.css';

const BackgroundAnimation = () => {
    const [trees, setTrees] = useState([]);

    useEffect(() => {
        // Initially fewer trees
        const initialTrees = Array.from({ length: 8 }).map(() => ({
            top: Math.random() * 100 + 'vh',
            left: Math.random() * 100 + 'vw',
            id: Math.random(),
        }));
        setTrees(initialTrees);

        // Add multiple trees at random intervals
        const interval = setInterval(() => {
            const newTrees = Array.from({ length: Math.floor(Math.random() * 3) + 3 }).map(() => ({
                top: Math.random() * 100 + 'vh',
                left: Math.random() * 100 + 'vw',
                id: Math.random(),
            }));
            setTrees((prevTrees) => [...prevTrees, ...newTrees]);

            // Remove trees after 5 seconds
            newTrees.forEach((tree) => {
                setTimeout(() => {
                    setTrees((prevTrees) => prevTrees.filter((t) => t.id !== tree.id));
                }, 5000);
            });
        }, 3000); // create new trees every 3 seconds

        return () => clearInterval(interval);
    }, []);

    const drawTreeAnimation = {
        initial: { pathLength: 0, opacity: 0 },
        animate: { pathLength: 1, opacity: 1, transition: { duration: 2 } },
        exit: { opacity: 0, transition: { duration: 2 } },
    };

    return (
        <div className="background-animation">
            {trees.map((tree) => (
                <motion.svg
                    key={tree.id}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="100"
                    viewBox="0 0 80 100"
                    className="christmas-tree"
                    style={{
                        position: 'absolute',
                        top: tree.top,
                        left: tree.left,
                    }}
                >
                    {/* Tree branches */}
                    <motion.polygon
                        points="40,10 60,40 20,40"
                        fill="green"
                        variants={drawTreeAnimation}
                    />
                    <motion.polygon
                        points="40,30 65,60 15,60"
                        fill="green"
                        variants={drawTreeAnimation}
                    />
                    <motion.polygon
                        points="40,50 70,80 10,80"
                        fill="green"
                        variants={drawTreeAnimation}
                    />
                    {/* Tree trunk */}
                    <motion.rect
                        x="35"
                        y="80"
                        width="10"
                        height="15"
                        fill="brown"
                        variants={drawTreeAnimation}
                    />
                </motion.svg>
            ))}
        </div>
    );
};

export default BackgroundAnimation;