import React, { useState } from 'react';
import './styles/App.css'; // Import the App.css file
import Countdown from './components/Countdown/Countdown';
import PDFViewer from './components/PDFViewer/PDFViewer';
import { AnimatePresence, motion } from 'framer-motion';
import BackgroundAnimation from './components/BackgroundAnimation/BackgroundAnimation';
import SnowEffect from './components/SnowEffect/SnowEffect';
import PasswordInput from './components/PasswordInput/PasswordInput';

function App() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPDF, setShowPDF] = useState(false);

  const handleCountdownComplete = () => {
        setShowPassword(true);
    };

  const handlePasswordCorrect = () => {
    console.log("Password correct! Showing PDF...");
      setShowPassword(false); // Hide password input
      setTimeout(() => setShowPDF(true), 500); // Flip animation delay
  };

  return (
    <div className="App">
            <BackgroundAnimation />
            <SnowEffect />
            <AnimatePresence>
                {!showPDF && !showPassword && (
                    <Countdown onComplete={handleCountdownComplete} />
                )}
                {showPassword && !showPDF && (
                    <motion.div
                        initial={{ opacity: 0, rotateY: 0 }}
                        animate={{ opacity: 1, rotateY: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <PasswordInput
                        onPasswordSuccess={handlePasswordCorrect} />
                    </motion.div>
                )}
                {showPDF && (
                    <motion.div
                        initial={{ rotateY: -180 }}
                        animate={{ rotateY: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <PDFViewer />
                    </motion.div>
                )}
            </AnimatePresence>
            {/* <button className="toggle-button" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'Hide' : 'Show'} Password Input
            </button> */}
    </div>
  );
}

export default App;