import React, { useState, useRef, useEffect } from "react";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import { List } from "react-virtualized";
import { supabase } from '../../supabase';
import "./PDFViewer.css";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-virtualized/styles.css';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`;

const PDFViewer = () => {
    const pdfFiles = [
        { name: "Volume 1", url: "/Document_vol1.pdf" },
        { name: "Volume 2", url: "/Document_vol2.pdf" },
        { name: "Volume 3", url: "/Document_vol3.pdf" },
        { name: "Volume 4", url: "/Document_vol4.pdf" },
    ];

    const [selectedPDF, setSelectedPDF] = useState(localStorage.getItem("lastVisitedDocument") || pdfFiles[0].url);
    const [numPages, setNumPages] = useState(0);
    const [pageWidth, setPageWidth] = useState(600);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [favorites, setFavorites] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem("lastVisitedPage"), 10) || 1); // Track the current page
    const listRef = useRef(null);

    useEffect(() => {
        const updatePageWidth = () => {
            setPageWidth(window.innerWidth < 768 ? window.innerWidth * 0.9 : 600);
        };

        updatePageWidth();
        window.addEventListener("resize", updatePageWidth);
        return () => window.removeEventListener("resize", updatePageWidth);
    }, []);

    useEffect(() => {
        const loadFavorites = async () => {
          const data = await fetchFavorites();
          setFavorites(data);
        };
        loadFavorites();
      }, []);

      useEffect(() => {
        localStorage.setItem("lastVisitedDocument", selectedPDF);
        localStorage.setItem("lastVisitedPage", currentPage);
      }, [selectedPDF, currentPage]);

      const handlePDFChange = (event) => {
        const newPDF = event.target.value;
        setSelectedPDF(newPDF);
        setCurrentPage(1);
        console.debug(`PDF changed to: ${newPDF}`);
      };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        console.debug(`Document loaded with ${numPages} pages.`);
        console.debug(`Starting at page: ${currentPage}`);
        if (listRef.current) {
            listRef.current.recomputeRowHeights();
            listRef.current.scrollToRow(currentPage - 1); // Scroll to the current page
        }
    };

    const toggleFavorite = async (documentName, pageNumber) => {
        const isFavorited = favorites.some(
          (fav) => fav.document_name === documentName && fav.page_number === pageNumber
        );

        if (isFavorited) {
            await supabase
              .from("favorites")
              .delete()
              .eq("document_name", documentName)
              .eq("page_number", pageNumber);
          } else {
            await supabase.from("favorites").insert([
              { document_name: documentName, page_number: pageNumber },
            ]);
          }

          const updatedFavorites = await supabase.from("favorites").select("*");
          setFavorites(updatedFavorites.data || []);
    };

    const goToPage = (documentName, pageNumber) => {
        setSelectedPDF(documentName);
        setCurrentPage(pageNumber);
        localStorage.setItem("lastVisitedDocument", documentName);
        localStorage.setItem("lastVisitedPage", pageNumber);
        setTimeout(() => {if (listRef.current) {
            listRef.current.scrollToRow(pageNumber - 1); // Navigate to the selected page
        }}, 300); // Delay to load the document
        setIsMenuOpen(false); // Close menu after navigation
      };

      const handleScroll = ({ scrollTop }) => {
        if (!listRef.current) return;

        const visibleIndex = scrollTop / rowHeight;
        if (Number.isNaN(visibleIndex)) return
        const visiblePage = Math.round(visibleIndex + 1.0);

        if ((visiblePage >= currentPage || visiblePage < currentPage - 1) && visiblePage >= 1 && visiblePage <= numPages) {
          setCurrentPage(visiblePage);
        }
      };

    const rowRenderer = ({ index, key, style }) => {
        const pageNumber = index + 1;

        return (<div key={key} style={style} className="page-container">
            <div className="page-content">
                <ReactPdfPage pageNumber={pageNumber} width={pageWidth} />
                <button
                        className={`favorite-btn ${favorites.some(
      (fav) => fav.document_name === selectedPDF && fav.page_number === index + 1
    ) ? 'favorited' : ''}`}
                        onClick={() => toggleFavorite(selectedPDF, pageNumber)}
                    >
                        ★
                    </button>
            </div>
            <div className="page-number">- {pageNumber} -</div>
            {index < numPages - 1 && <div className="page-separator"></div>}
        </div>)
    };

    const fetchFavorites = async () => {
        const { data, error } = await supabase.from('favorites').select('*');
        if (error) console.error('Error fetching favorites:', error);
        return data || [];
      };

    const aspectRatio = 11 / 8.5;
    const pageHeight = pageWidth * aspectRatio
    const rowHeight = pageHeight + 80;

    return (
        <div className="pdf-viewer">
            <div className="header">
                <select onChange={handlePDFChange} value={selectedPDF}>
                    {pdfFiles.map((file, index) => (
                        <option key={index} value={file.url}>
                            {file.name}
                        </option>
                    ))}
                </select>
                <button className="hamburger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    ☰
                </button>
            </div>

            {isMenuOpen && (
                <div className="menu">
                    <div className="favorites-list">
                        <h3>Favorite Pages</h3>
                        <ul>
                            {favorites.map((fav) => (
                                <li key={fav.id}>
                                    <button onClick={() => goToPage(fav.document_name, fav.page_number)}>
                                        {fav.document_name} - Page {fav.page_number}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            <div className="pdf-container">
                <Document
                    file={selectedPDF}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <List
                    ref={listRef}
                    width={pageWidth}
                    height={800}
                    rowCount={numPages}
                    rowHeight={rowHeight} // Adjust row height based on page aspect ratio
                    rowRenderer={rowRenderer}
                    scrollToIndex={currentPage - 1} // Scroll to the current page
                    overscanRowCount={2} // Preload a few rows for smooth scrolling
                    className="custom-virtualized-list" // Optional custom CSS class
                    onScroll={({ scrollTop }) => handleScroll({ scrollTop })}
                    />

                </Document>
            </div>
        </div>
    );
};

export default PDFViewer;
