// src/components/PasswordInput/PasswordInput.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './PasswordInput.css';

const PasswordInput = ({ onPasswordSuccess }) => {
    const [segments, setSegments] = useState(['', '', '', '']); // Store four segments of the password
    const [showPassword, setShowPassword] = useState(false); // Toggle visibility
    const correctPassword = process.env.REACT_APP_PASSWORD; // Replace with your password

    const handleSegmentChange = (index, value) => {
        // Update the corresponding segment
        const updatedSegments = [...segments];
        updatedSegments[index] = value;
        setSegments(updatedSegments);
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        const enteredPassword = segments.join('-');
        if (enteredPassword === correctPassword) {
            console.log("Password correct! Calling onPasswordSuccess...");
            onPasswordSuccess();
        } else {
            alert('Incorrect password. Try again.');
            setSegments(['', '', '', '']); // Clear inputs
        }
    };

    return (
        <motion.div
            className="password-input-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <form className="password-form" onSubmit={handlePasswordSubmit}>
                <label htmlFor="password">🔑</label>
                <div className="password-input-group">
                    {segments.map((segment, index) => (
                        <React.Fragment key={index}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                maxLength={8}
                                className="password-input"
                                value={segment}
                                onChange={(e) => handleSegmentChange(index, e.target.value)}
                                placeholder="00000000"
                            />
                            {index < segments.length - 1 && (
                                <span className="password-delimiter">-</span>
                            )}
                        </React.Fragment>
                    ))}
                    <button
                        type="button"
                        className="eye-button"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? '👁️' : '🙈'}
                    </button>
                </div>
                <button type="submit">🎁</button>
            </form>
        </motion.div>
    );
};

export default PasswordInput;