import React from 'react';
import Countdown from 'react-countdown';
import './Countdown.css';

const CountdownComponent = ({ onComplete }) => {
    const targetDate = new Date('December 25, 2024 00:00:00');

    return (
        <Countdown
            date={targetDate}
            onComplete={onComplete}
            renderer={({ days, hours, minutes, seconds, completed }) => {
                if (completed) {
                    return null; // Hide countdown after completion
                }
                return (<div className="countdown">
                    <div className="time-unit">
                        <span className="time-value">{days}</span>
                        <span className="time-label">Days</span>
                    </div>
                    <div className="time-unit">
                        <span className="time-value">{hours}</span>
                        <span className="time-label">Hours</span>
                    </div>
                    <div className="time-unit">
                        <span className="time-value">{minutes}</span>
                        <span className="time-label">Minutes</span>
                    </div>
                    <div className="time-unit">
                        <span className="time-value">{seconds}</span>
                        <span className="time-label">Seconds</span>
                    </div>
                </div>)
                }
            }
        />
    );
};

export default CountdownComponent;